import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subjects: {},
  stories: {},
  teachers: {},
  upcomingQuiz: {},
  completedQuiz: {},
  upcomingliveClasses: {},
  completedliveClasses: {},
  courses: {},
  enotes: {},
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setSubjects(state, action) {
      state.subjects = action.payload;
    },
    setStories(state, action) {
      state.stories = action.payload;
    },
    setTeachers(state, action) {
      state.teachers = action.payload;
    },
    setUpcomingQuiz(state, action) {
      state.upcomingQuiz = action.payload;
    },
    setCompletedQuiz(state, action) {
      state.completedQuiz = action.payload;
    },
    setUpcomingLiveClasses(state, action) {
      state.upcomingliveClasses = action.payload;
    },
    setCompletedLiveClasses(state, action) {
      state.completedliveClasses = action.payload;
    },
    setCourses(state, action) {
      state.courses = action.payload;
    },
    setEnotes(state, action) {
      state.enotes = action.payload;
    },
  },
});

export const {
  setSubjects,
  setStories,
  setTeachers,
  setUpcomingQuiz,
  setCompletedQuiz,
  setUpcomingLiveClasses,
  setCompletedLiveClasses,
  setCourses,
  setEnotes,
} = dataSlice.actions;

export const getCourses = (state) => state.data.courses;
export default dataSlice.reducer;
