import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { fetchCompletedQuiz, fetchUpcomingQuiz } from "../../../services/quiz";
import {
  setCompletedQuiz,
  setUpcomingQuiz,
} from "../../../store/build/dataSlice";
import { toDateTimeString } from "../../../utils/utils";
import AddButton from "../../shared/AddButton";
import ContentDialog from "../../shared/ContentDialog";

export default function Quiz(props) {
  const dispatch = useDispatch();
  const upcoming = useQuery("upcoming", fetchUpcomingQuiz, {
    onSuccess: (data) => {
      dispatch(setUpcomingQuiz(data.data));
    },
  });

  const completed = useQuery("completed", fetchCompletedQuiz, {
    onSuccess: (data) => {
      dispatch(setCompletedQuiz(data.data));
    },
  });

  const [value, setValue] = useState("upcoming");
  const [questionOpen, setQuestionOpen] = useState(false);
  const [questionArray, setQuestionArray] = useState([]);
  const openQuestionsHandler = (questions) => {
    setQuestionArray(questions);
    isEmpty(questions)
      ? toast.error("No Questions Present")
      : setQuestionOpen(true);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (upcoming !== null) dispatch(setUpcomingQuiz(upcoming.data));

    if (completed !== null) dispatch(setCompletedQuiz(completed.data));
  }, [dispatch, upcoming, completed]);

  if (value === "upcoming" && (isNil(upcoming) || upcoming.isLoading))
    return null;
  if (value === "completed" && (isNil(completed) || completed.isLoading))
    return null;

  return (
    <div className={`${props.class.container}`}>
      <div className={props.class.heading}>
        <h1>Quiz</h1>
        <AddButton color="secondary">Add New Quiz</AddButton>
      </div>
      <FormControl>
        <RadioGroup
          className="flex !flex-row"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="upcoming"
            control={<Radio />}
            label="Upcoming Quiz"
          ></FormControlLabel>
          <FormControlLabel
            value="completed"
            control={<Radio />}
            label="Completed Quiz"
          ></FormControlLabel>
        </RadioGroup>
      </FormControl>
      <div className={props.class.sub_container}>
        {value === "upcoming"
          ? upcoming.data.data.map((quiz, idx) => (
              <QuizCard
                key={quiz._id}
                startDate={quiz.startDate}
                endDate={quiz.endDate}
                count={quiz.students.length}
                questions={() => openQuestionsHandler(quiz.questions)}
              />
            ))
          : completed.data.data.map((quiz, idx) => (
              <QuizCard
                key={quiz._id}
                startDate={quiz.startDate}
                endDate={quiz.endDate}
                count={quiz.students.length}
                questions={() => openQuestionsHandler(quiz.questions)}
              />
            ))}
      </div>
      <ContentDialog
        isOpen={questionOpen}
        closeDialog={() => setQuestionOpen(false)}
        title="Questions"
        action="Close"
      >
        {questionArray.map((question, idx) => (
          <div className="bg-white mb-2">
            <h1 className="mb-1">{question.question}</h1>
            <ul className="list-disc pl-4">
              <li>{question.option1}</li>
              <li>{question.option2}</li>
              <li>{question.option3}</li>
              <li>{question.option4}</li>
            </ul>
          </div>
        ))}
      </ContentDialog>
    </div>
  );
}

const QuizCard = (props) => {
  return (
    <Card variant="outlined" key={props.id} className="relative">
      <CardContent>
        <div className="flex flex-col font-normal text-sm justify-between">
          <div className="flex flex-row items-center mb-1">
            <div className="bg-white p-1 mr-1">Start Time</div>
            <h1>{toDateTimeString(props.startDate)}</h1>
          </div>
          <div className="flex flex-row items-center">
            <div className="bg-white p-1 mr-1">End Time</div>
            <h1>{toDateTimeString(props.endDate)}</h1>
          </div>
        </div>
        <div className="absolute right-2 top-2 bg-white rounded p-1">
          {props.count}
        </div>
      </CardContent>
      <CardActions>
        <Button onClick={props.questions}>View Questions</Button>
      </CardActions>
    </Card>
  );
};
