import { Fade, IconButton, Modal } from "@mui/material";
import React from "react";
import classes from "./BaseModal.module.scss";
import { Close as CloseIcon } from "@mui/icons-material";
export default function BaseModal({
  isOpen,
  onClose,
  heading,
  children,
  footerChildren,
}) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      aria-labelledby={heading}
      classes={{ root: "flex items-center justify-center" }}
    >
      <Fade in={isOpen}>
        <div className={classes.content}>
          <header className={classes.header}>
            <div>
              {/* {icon}
                {icon && <span className="mx-1 opacity-25">/</span>} */}
              <h1>{heading}</h1>
            </div>

            <IconButton size="small" onClick={onClose}>
              <CloseIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </header>
          <div className={classes.body}>{children}</div>
          {footerChildren ? (
            <footer className={classes.footer}>{footerChildren}</footer>
          ) : null}
        </div>
      </Fade>
    </Modal>
  );
}
