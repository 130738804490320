import { joiResolver } from "@hookform/resolvers/joi";
import { Button, TextField } from "@mui/material";
import Joi from "joi";
import { get } from "lodash";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { addNewEnotesCourse } from "../../services/subjects";
import { setModalState } from "../../store/modal/modalSlice";
import BaseModal from "../BaseModal";

export default function AddEnoteCourses({ refetch }) {
  const dispatch = useDispatch();

  const { open: isOpen, id: board_id } = useSelector((state) =>
    get(state.modal, "modal.addEnoteCourses")
  );

  const schema = Joi.object({
    board_id: Joi.string().required(),
    name: Joi.string().required(),
  });

  useEffect(() => {
    setValue("board_id", board_id, {});
  }, [board_id]);

  const defaultState = {
    board_id: "",
    name: "",
  };

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: defaultState,
    resolver: joiResolver(schema),
  });

  const { mutateAsync: addBoardMutation } = useMutation(addNewEnotesCourse);

  const onSubmit = async ({ name, board_id }) => {
    await addBoardMutation(
      { name, board_id },
      {
        onError: (error) => {
          toast.error(error.message);
        },
        onSuccess: (data) => {
          dispatch(
            setModalState({
              modal: "modal.addEnoteCourses",
              state: { open: false, refetch: true },
            })
          );
          reset();
        },
      }
    );
  };

  const handleClose = () => {
    dispatch(
      setModalState({
        modal: "modal.addEnoteCourses",
        state: { open: false, refetch: false },
      })
    );
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={handleClose}
      heading="Add New Course"
      footerChildren={
        <Button onClick={handleSubmit(onSubmit)}>Add Board</Button>
      }
    >
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            label="Enter Course Name"
            size="small"
            error={!!fieldState.error}
            {...field}
          />
        )}
      />
    </BaseModal>
  );
}
