import { ThemeProvider } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, lightTheme } from "../config/theme";
import { getTheme, setTheme } from "../store/build/buildSlice";

export default function ThemeWrapper(props) {
  const dispatch = useDispatch();
  const theme = useSelector(getTheme);
  const isDarkMode = useMemo(() => theme === "dark", [theme]);

  const muiTheme = useMemo(
    () => (isDarkMode ? darkTheme : lightTheme),
    [isDarkMode]
  );

  useEffect(() => {
    if (theme === undefined) {
      dispatch(setTheme({ theme: "dark" }));
    }
  }, [theme, dispatch]);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDarkMode]);

  return <ThemeProvider theme={muiTheme}>{props.children}</ThemeProvider>;
}
