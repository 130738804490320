import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  accessToken: null,
  isLoggedIn: false,
  permission: {},
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    setPermission(state, action) {
      state.permission = action.payload;
    },
    logout: () => initialState,
  },
});

export const { setUser, setAccessToken, setLoggedIn, setPermission, logout } =
  authSlice.actions;
export const getToken = (state) => state.auth.accessToken;
export const getUserId = (state) => state.auth.user;
export const getIsLoggedIn = (state) => state.auth.isLoggedIn;
export const getPermissions = (state) => state.auth.permission;

export default authSlice.reducer;
