import { Button, TextField } from "@mui/material";
import React, { useEffect } from "react";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { login } from "../../services/auth";
import toast from "react-hot-toast";
import { getIsLoggedIn } from "../../store/auth/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(getIsLoggedIn);

  useEffect(() => {
    isLoggedIn && navigate("/dashboard/home", { replace: true });
  }, [isLoggedIn, navigate]);

  const schema = Joi.object({
    email: Joi.string().required(),
    password: Joi.string().required(),
  });

  const defaultState = {
    email: "",
    password: "",
  };

  const { control, handleSubmit } = useForm({
    defaultValues: defaultState,
    resolver: joiResolver(schema),
  });

  const { mutateAsync: loginMutation } = useMutation(login);

  const onSubmit = async (email, password) => {
    await loginMutation(email, password, {
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  return (
    <div className="flex flex-col px-5 justify-center h-screen lg:w-1/3 lg:m-auto">
      <div className="flex flex-col h-1/5 justify-around lg:h-1/5">
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              label="Username"
              size="small"
              error={!!fieldState.error}
              {...field}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              label="Password"
              size="small"
              type="password"
              error={!!fieldState.error}
              {...field}
            />
          )}
        />
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Login
        </Button>
      </div>
    </div>
  );
}
