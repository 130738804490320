import axios from "./axios";

export const fetchTeachers = async () => {
  const { data } = await axios.get("/teachers");
  return data;
};

export const addNewTeacher = async ({
  name,
  number,
  picture,
  degree,
  success,
  experience,
  trained,
}) => {
  await axios.post("/create/teacher", { name });
};
