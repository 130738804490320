import { joiResolver } from "@hookform/resolvers/joi";
import { Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  IconButton,
  Input,
  InputAdornment,
  TextField,
} from "@mui/material";
import Joi from "joi";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import UploadImageButton from "../../components/shared/UploadImageButton";
import { addNewCourse } from "../../services/subjects";
import { fetchTeachers } from "../../services/teachers";
import { getFiles } from "../../store/build/buildSlice";
import { setTeachers } from "../../store/build/dataSlice";
import { setModalState } from "../../store/modal/modalSlice";
import BaseModal from "../BaseModal";

export default function AddCourse() {
  const dispatch = useDispatch();
  const [teacherValue, setTeacherValue] = useState("");
  const { open: isOpen, id: subject_id } = useSelector((state) =>
    get(state.modal, "modal.addCourse")
  );

  const schema = Joi.object({
    subject_id: Joi.string().required(),
    name: Joi.string().required(),
    picture: Joi.object().required(),
    price: Joi.string().required(),
    discount_price: Joi.string().required(),
    overview: Joi.string().required(),
    learn: Joi.array().required(),
    teacher_id: Joi.array(),
    target: Joi.array(),
  });

  const files = useSelector(getFiles);

  const teachers = useQuery("teachers", fetchTeachers, {
    onSuccess: (data) => {
      dispatch(setTeachers(data.data));
    },
  });

  useEffect(() => {
    files.forEach(
      (x) =>
        x.name === "courseImage" &&
        setValue("picture", x.image, {
          shouldDirty: true,
          shouldValidate: true,
        })
    );
  }, [files]);

  useEffect(() => {
    setValue("teacher_id", teacherValue, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [teacherValue]);

  useEffect(() => {
    setValue("subject_id", subject_id, {});
  }, [subject_id]);

  const defaultState = {
    subject_id: "",
    name: "",
    picture: "",
    teacher_id: [],
    price: "",
    learn: [],
    overview: "",
    target: [],
    discount_price: "",
  };

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: defaultState,
    resolver: joiResolver(schema),
  });

  const {
    fields: requirementFields,
    remove,
    insert,
  } = useFieldArray({
    control,
    name: "target",
  });

  const { insert: insertTeacher } = useFieldArray({
    control,
    name: "teacher_id",
  });

  const {
    fields: learnFields,
    remove: learnRemove,
    insert: learnInsert,
  } = useFieldArray({
    control,
    name: "learn",
  });

  const { mutateAsync: addCourseMutation } = useMutation(addNewCourse);

  const onSubmit = async ({
    subject_id,
    name,
    picture,
    teacher_id,
    price,
    learn,
    overview,
    target,
    discount_price,
  }) => {
    await addCourseMutation(
      {
        subject_id,
        name,
        picture,
        teacher_id,
        price,
        learn,
        overview,
        target,
        discount_price,
      },
      {
        onError: (error) => {
          toast.error(error.message);
        },
        onSuccess: (data) => {
          console.log(data);
          dispatch(
            setModalState({
              modal: "modal.addCourse",
              state: { open: false, refetch: true, id: "" },
            })
          );
          reset();
        },
      }
    );
  };

  const handleClose = () => {
    reset();
    dispatch(
      setModalState({
        modal: "modal.addCourse",
        state: { open: false, refetch: false, id: "" },
      })
    );
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={handleClose}
      heading="Add New Course"
      footerChildren={
        <Button onClick={handleSubmit(onSubmit)}>Add Course</Button>
      }
    >
      <Controller
        name="picture"
        control={control}
        render={({ field, fieldState }) => (
          <UploadImageButton
            name="Upload Course Image"
            filePath="courseImage"
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            label="Enter Course Name"
            size="small"
            error={!!fieldState.error}
            {...field}
          />
        )}
      />
      <div className="gap-3 flex flex-col lg:flex-row justify-between">
        <Controller
          name="price"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className="w-full"
              placeholder="Price"
              type="number"
              error={!!fieldState.error}
              startAdornment={
                <InputAdornment position="start">₹</InputAdornment>
              }
              {...field}
            />
          )}
        />
        <Controller
          name="discount_price"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className="w-full"
              placeholder="Discounted Price"
              type="number"
              error={!!fieldState.error}
              startAdornment={
                <InputAdornment position="start">₹</InputAdornment>
              }
              {...field}
            />
          )}
        />
      </div>
      <Controller
        name="overview"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            label="Enter Course Overview"
            size="small"
            error={!!fieldState.error}
            multiline={true}
            rows={3}
            {...field}
          />
        )}
      />
      <div className="flex flex-col">
        {learnFields.map((field, learnIndex) => (
          <div
            key={field.id}
            className="flex flex-row w-full justify-between items-center"
          >
            <Controller
              name={`learn.${learnIndex}`}
              control={control}
              render={({ field }) => (
                <TextField
                  className="mb-4 w-1/2"
                  label="Enter What you will Learn"
                  size="small"
                  {...field}
                />
              )}
            />

            <IconButton onClick={() => learnRemove(learnIndex)}>
              <Delete color="error" />
            </IconButton>
          </div>
        ))}
        <Button onClick={() => learnInsert(parseInt(2, 10), "")}>
          Add What You will Learn
        </Button>
      </div>
      <div className="flex flex-col">
        {requirementFields.map((field, index) => (
          <div
            key={field.id}
            className="flex flex-row w-full justify-between items-center"
          >
            <Controller
              name={`target.${index}`}
              control={control}
              render={({ field }) => (
                <TextField
                  className="mb-4 w-1/2"
                  label="Enter Requirements"
                  size="small"
                  {...field}
                />
              )}
            />
            <IconButton onClick={() => remove(index)}>
              <Delete color="error" />
            </IconButton>
          </div>
        ))}
        <Button onClick={() => insert(parseInt(2, 10), "")}>
          Add Requirements
        </Button>
      </div>
      <Controller
        name="teacher_id"
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            multiple
            limitTags={-1}
            autoComplete
            id="multiple-limit-tags"
            options={teachers.data.data}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              console.log(newValue);
              setTeacherValue(newValue);
              insertTeacher(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select Teachers"
                placeholder="Select Teachers"
              />
            )}
          />
        )}
      />
    </BaseModal>
  );
}
