import { joiResolver } from "@hookform/resolvers/joi";
import { Button, TextField } from "@mui/material";
import Joi from "joi";
import { get } from "lodash";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { addNewNotesToCourse } from "../../services/subjects";
import { setModalState } from "../../store/modal/modalSlice";
import BaseModal from "../BaseModal";

export default function AddNewNotesToCourses({ refetch }) {
  const dispatch = useDispatch();

  const {
    open: isOpen,
    id: board_id,
    course_id,
  } = useSelector((state) => get(state.modal, "modal.addNotesToCourses"));

  const schema = Joi.object({
    board_id: Joi.string().required(),
    course_id: Joi.string().required(),
    name: Joi.string().required(),
    notes: Joi.object().required(),
  });

  useEffect(() => {
    setValue("board_id", board_id, {});
  }, [board_id]);
  useEffect(() => {
    setValue("course_id", course_id, {});
  }, [course_id]);

  const defaultState = {
    board_id: "",
    name: "",
    course_id: "",
    notes: "",
  };

  const onFileUpload = (event) => {
    setValue("notes", event.target.files[0], {});
  };

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: defaultState,
    resolver: joiResolver(schema),
  });

  const { mutateAsync: addBoardMutation } = useMutation(addNewNotesToCourse);

  const onSubmit = async ({ name, board_id, course_id, notes }) => {
    await addBoardMutation(
      { name, board_id, course_id, notes },
      {
        onError: (error) => {
          toast.error(error.message);
        },
        onSuccess: (data) => {
          dispatch(
            setModalState({
              modal: "modal.addNotesToCourses",
              state: { open: false, refetch: true, id: "", course_id: "" },
            })
          );
          reset();
        },
      }
    );
  };

  const handleClose = () => {
    dispatch(
      setModalState({
        modal: "modal.addNotesToCourses",
        state: { open: false, refetch: false, id: "", course_id: "" },
      })
    );
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={handleClose}
      heading="Add New Notes"
      footerChildren={
        <Button onClick={handleSubmit(onSubmit)}>Add Board</Button>
      }
    >
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            label="Enter Notes Title"
            size="small"
            error={!!fieldState.error}
            {...field}
          />
        )}
      />
      <Controller
        name="notes"
        control={control}
        render={({ field, fieldState }) => (
          <input type="file" onChange={onFileUpload} name="notes" />
        )}
      />
    </BaseModal>
  );
}
