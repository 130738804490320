import _axios from "axios";
import toast from "react-hot-toast";
import { BASE_URL } from "../constants/constants";
import store from "../store";
import { logout } from "../store/auth/authSlice";

const axios = _axios.create({ baseURL: BASE_URL });

axios.interceptors.request.use((config) => {
  const { accessToken } = store.getState().auth;

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${accessToken}`,
  };

  return config;
});
axios.interceptors.response.use(
  (response) => {
    if (response) {
      try {
        const code = response.data.code;
        if (code === 401 || code === 403) {
          store.dispatch(logout());
        }
        if (code === 402) {
          toast.error("You Don't Have Permission to Access this");
        }
      } catch {}
    }
    return response;
  },
  (error) => {
    const { response } = error;

    if (response) {
      const errorObject = response.data;
      throw errorObject;
    }

    throw error;
  }
);
export default axios;
