import { Button, Card, CardActions, CardContent } from "@mui/material";
import { get, isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { NOTES_URL } from "../../../constants/constants";
import BaseModal from "../../../modals/BaseModal";
import { fetchCoursesByBoard } from "../../../services/subjects";
import { setEnotes } from "../../../store/build/dataSlice";
import { setModalState } from "../../../store/modal/modalSlice";
import AddButton from "../../shared/AddButton";

export default function EnotesCourses(props) {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [notes, setNotes] = useState({});
  const [viewNotes, setViewNotes] = useState(false);
  const enotescourses = useQuery(
    ["enotesCourses", searchParams.get("id")],
    fetchCoursesByBoard,
    {}
  );

  const { refetch: isRefetch } = useSelector((state) =>
    get(state.modal, "modal.addEnoteCourses")
  );
  useEffect(() => {
    async function refetchUI() {
      await enotescourses.refetch();
    }
    refetchUI();
    dispatch(
      setModalState({
        modal: "modal.addEnoteCourses",
        state: { open: false, refetch: false, id: "" },
      })
    );
  }, [isRefetch, dispatch]);

  const openModalHandler = () => {
    dispatch(
      setModalState({
        modal: "modal.addEnoteCourses",
        state: { open: true, refetch: false, id: searchParams.get("id") },
      })
    );
  };

  const openNotesInWindow = (link) => {
    window.open(`${NOTES_URL}${link}`, "_blank");
  };

  const openViewNotesModalHandler = (course_id) => {
    setViewNotes(true);
    enotescourses &&
      enotescourses.data.data.courses.forEach((course) => {
        if (course._id === course_id) {
          setNotes(course);
        }
        return;
      });
  };
  const openAddNotesModalHandler = (course_id) => {
    dispatch(
      setModalState({
        modal: "modal.addNotesToCourses",
        state: {
          open: true,
          refetch: false,
          id: searchParams.get("id"),
          course_id,
        },
      })
    );
  };

  useEffect(() => {
    if (enotescourses.data) dispatch(setEnotes(enotescourses.data));
  }, [dispatch, enotescourses]);

  if (
    !enotescourses ||
    isEmpty(enotescourses) ||
    isNil(enotescourses) ||
    enotescourses.isLoading
  )
    return null;

  return (
    <div className={`${props.class.container}`}>
      <div className={props.class.heading}>
        <h1>{`${searchParams.get("name")}-Enotes`}</h1>
        <AddButton color="secondary" onClick={openModalHandler}>
          Add New Board
        </AddButton>
      </div>
      <div className="lg:grid lg:grid-cols-2 m-auto">
        {enotescourses.data.data.courses.map((course, index) => (
          <Card
            className="relative w-full h-40 flex flex-col w-9/10 mb-6 lg:mb-6 lg:w-4/6 m-auto justify-between"
            variant="outlined"
          >
            <CardContent>{course.name}</CardContent>
            <CardActions className="flex flex-row justify-between">
              <Button onClick={() => openViewNotesModalHandler(course._id)}>
                View Notes
              </Button>
              <Button onClick={() => openAddNotesModalHandler(course._id)}>
                Add Notes
              </Button>
            </CardActions>
          </Card>
        ))}
      </div>
      {viewNotes && (
        <BaseModal
          isOpen={viewNotes}
          onClose={() => setViewNotes(false)}
          heading="All Notes"
          footerChildren={
            <Button onClick={() => setViewNotes(false)}>Close</Button>
          }
        >
          {notes.notes.map((note, index) => (
            <div className="flex flex-row justify-between">
              <div>{note.name}</div>
              <div
                className="underline text-blue-700 cursor-pointer"
                onClick={() => openNotesInWindow(note.pdf)}
              >
                {note.pdf}
              </div>
            </div>
          ))}
        </BaseModal>
      )}
    </div>
  );
}
