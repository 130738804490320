import axios from "./axios";

export const fetchStories = async () => {
  const { data } = await axios.get("/stories");
  return data;
};

export const deleteStory = async ({ story_id }) => {
  await axios.post("/delete/story", { story_id });
};
