import { joiResolver } from "@hookform/resolvers/joi";
import { Delete } from "@mui/icons-material";
import { Button, IconButton, TextField } from "@mui/material";
import Joi from "joi";
import { get } from "lodash";
import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import DateTimePickerCompnent from "../../components/shared/DateTimePicker";
import UploadImageButton from "../../components/shared/UploadImageButton";
import { addNewCompetition } from "../../services/competitions";
import { getDateTime, getFiles } from "../../store/build/buildSlice";

import { setModalState } from "../../store/modal/modalSlice";
import BaseModal from "../BaseModal";

export default function AddCompetition() {
  const dispatch = useDispatch();
  const files = useSelector(getFiles);
  const dates = useSelector(getDateTime);
  const { open: isOpen } = useSelector((state) =>
    get(state.modal, "modal.addCompetition")
  );

  const schema = Joi.object({
    name: Joi.string().required(),
    picture: Joi.object().required(),
    start: Joi.date().required(),
    entry_end: Joi.date().required(),
    top_ten_end: Joi.date().required(),
    voting_lines_end: Joi.date().required(),
    result_end: Joi.date().required(),
    about: Joi.array().required(),
  });

  const defaultState = {
    name: "",
    picture: {},
    start: "",
    entry_end: "",
    top_ten_end: "",
    voting_lines_end: "",
    result_end: "",
    about: [],
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: defaultState,
    resolver: joiResolver(schema),
  });

  const { fields, remove, insert } = useFieldArray({
    control,
    name: "about",
  });

  // console.log(errors);
  // console.log(getValues());
  useEffect(() => {
    files.forEach(
      (x) =>
        x.name === "competitionImg" &&
        setValue("picture", x.image, { shouldTouch: true })
    );
  }, [files]);

  useEffect(() => {
    dates.forEach((x) => {
      if (x.name === "start_date") {
        setValue("start", x.value, { shouldTouch: true });
      }
      if (x.name === "entry_end_date") {
        setValue("entry_end", x.value, { shouldTouch: true });
      }
      if (x.name === "top_ten_end_date") {
        setValue("top_ten_end", x.value, { shouldTouch: true });
      }
      if (x.name === "voting_end_date") {
        setValue("voting_lines_end", x.value, { shouldTouch: true });
      }
      if (x.name === "result_end_date") {
        setValue("result_end", x.value, { shouldTouch: true });
      }
    });
  }, [dates]);

  const { mutateAsync: addCompetitionMutation } =
    useMutation(addNewCompetition);

  const onSubmit = async ({
    start,
    entry_end,
    top_ten_end,
    voting_lines_end,
    result_end,
    name,
    about,
    picture,
  }) => {
    console.log({
      start,
      entry_end,
      top_ten_end,
      voting_lines_end,
      result_end,
      name,
      about,
      picture,
    });
    await addCompetitionMutation(
      {
        start,
        entry_end,
        top_ten_end,
        voting_lines_end,
        result_end,
        name,
        about,
        picture,
      },
      {
        onError: (error) => {
          toast.error(error.message);
        },
        onSuccess: (data) => {
          dispatch(
            setModalState({
              modal: "modal.addCompetition",
              state: { open: false, refetch: true },
            })
          );
          reset();
        },
      }
    );
  };

  const handleClose = () => {
    dispatch(
      setModalState({
        modal: "modal.addCompetition",
        state: { open: false, refetch: false },
      })
    );
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={handleClose}
      heading="Add New Competition"
      footerChildren={
        <Button onClick={handleSubmit(onSubmit)}>Add Competition</Button>
      }
    >
      <Controller
        name="picture"
        control={control}
        render={({ field, fieldState }) => (
          <UploadImageButton
            name="Competition Logo"
            filePath="competitionImg"
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            label="Enter Competition Name"
            size="small"
            error={!!fieldState.error}
            {...field}
          />
        )}
      />
      <Controller
        name="start"
        control={control}
        render={({ field, fieldState }) => (
          <DateTimePickerCompnent
            name="Start Date"
            state="start_date"
            isTime={true}
          />
        )}
      />
      <Controller
        name="entry_end"
        control={control}
        render={({ field, fieldState }) => (
          <DateTimePickerCompnent
            isTime={true}
            name="Entry End / Top Ten Selection Start"
            state="entry_end_date"
          />
        )}
      />
      <Controller
        name="top_ten_end"
        control={control}
        render={({ field, fieldState }) => (
          <DateTimePickerCompnent
            isTime={true}
            name="Top Ten Selection End / Voting Lines Start"
            state="top_ten_end_date"
          />
        )}
      />
      <Controller
        name="voting_lines_end"
        control={control}
        render={({ field, fieldState }) => (
          <DateTimePickerCompnent
            isTime={true}
            name="Voting Lines End / Result Display Start"
            state="voting_end_date"
          />
        )}
      />
      <Controller
        name="result_end"
        control={control}
        render={({ field, fieldState }) => (
          <DateTimePickerCompnent
            isTime={true}
            name="Competition End Date"
            state="result_end_date"
          />
        )}
      />
      <div className="flex flex-col">
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="flex flex-row w-full justify-between items-center"
          >
            <Controller
              name={`about.${index}`}
              control={control}
              render={({ field }) => (
                <TextField
                  className="mb-4 w-1/2"
                  label="About the Competition"
                  size="small"
                  {...field}
                />
              )}
            />

            <IconButton onClick={() => remove(index)}>
              <Delete color="error" />
            </IconButton>
          </div>
        ))}
        <Button onClick={() => insert(parseInt(2, 10), "")}>Add About</Button>
      </div>
    </BaseModal>
  );
}
