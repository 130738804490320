import React from "react";
import { useIsFetching, useIsMutating } from "react-query";
import classes from "./Loading.module.scss";
// import { matchPath } from "react-router";

export default function Loading() {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  if (!isFetching && !isMutating) return null;
  return <div className={classes.loading} />;
}
