import { Button, Card, CardActions, CardContent } from "@mui/material";
import { get, isEmpty, isNil } from "lodash";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { fetchBoardsBySubject } from "../../../services/subjects";
import { setModalState } from "../../../store/modal/modalSlice";
import AddButton from "../../shared/AddButton";

export default function Enotes(props) {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const boards = useQuery(
    ["boards", searchParams.get("id")],
    fetchBoardsBySubject,
    {}
  );

  const { refetch: isRefetch } = useSelector((state) =>
    get(state.modal, "modal.addBoard")
  );
  useEffect(() => {
    console.log("refetch");
    async function refetchUI() {
      await boards.refetch();
    }
    refetchUI();
    dispatch(
      setModalState({
        modal: "modal.addBoard",
        state: { open: false, refetch: false, id: "" },
      })
    );
  }, [isRefetch, dispatch]);

  const openEnotesCoursesHandler = (name, board_id) => {
    navigate(`courses?name=${name}&id=${board_id}`);
  };

  const openModalHandler = () => {
    dispatch(
      setModalState({
        modal: "modal.addBoard",
        state: { open: true, refetch: false, id: searchParams.get("id") },
      })
    );
  };

  if (!boards || isEmpty(boards) || isNil(boards) || boards.isLoading)
    return null;

  return (
    <div className={`${props.class.container}`}>
      <div className={props.class.heading}>
        <h1>{`${searchParams.get("name")}-Enotes`}</h1>
        <AddButton color="secondary" onClick={openModalHandler}>
          Add New Board
        </AddButton>
      </div>
      <div className="lg:grid lg:grid-cols-2 m-auto">
        {boards.data.data.boards.map((subject, index) => (
          <Card
            className="relative w-full h-40 flex flex-col w-9/10 mb-6 lg:mb-6 lg:w-4/6 m-auto justify-between"
            variant="outlined"
          >
            <CardContent>{subject.name}</CardContent>
            <CardActions>
              <Button
                onClick={() =>
                  openEnotesCoursesHandler(subject.name, subject._id)
                }
              >
                All Courses
              </Button>
            </CardActions>
          </Card>
        ))}
      </div>
    </div>
  );
}
