import axios from "./axios";

export const fetchUpcomingQuiz = async () => {
  const { data } = await axios.get("/upcoming/quiz");
  return data;
};

export const fetchCompletedQuiz = async () => {
  const { data } = await axios.get("/completed/quiz");
  return data;
};
