import { useTheme } from "@emotion/react";
import { IconButton, Tooltip, useMediaQuery } from "@mui/material";
import React from "react";
import SidebarItems from "../../../config/sidebar";
import Logo from "../../../assets/logo.png";
import classes from "./Sidebar.module.scss";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { logout } from "../../../store/auth/authSlice";
import { useDispatch } from "react-redux";

export default function Sidebar() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const clickHandler = (route) => {
    navigate(route, { replace: true });
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div className={classes.container}>
      <div className="h-screen flex flex-col justify-around ">
        <img
          src={Logo}
          className="w-full md:w-1/3 md:mx-10 md:p-0 md:pt-5"
          alt="Logo"
        />
        {SidebarItems.map(({ id, icon }) => (
          <div
            className="cursor-pointer"
            key={id}
            onClick={
              id !== "Logout"
                ? () => clickHandler(id.replace(" ", "").toLowerCase())
                : logoutHandler
            }
          >
            <Tooltip arrow key={id} placement="right" title={id}>
              <div className={classes.section}>
                <IconButton
                  color={
                    location.pathname.includes(
                      id.replace(" ", "").toLowerCase()
                    )
                      ? "secondary"
                      : "primary"
                  }
                >
                  {icon}
                </IconButton>
                {isDesktop ? <div>{id}</div> : <></>}
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
}
