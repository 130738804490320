import axios from "./axios";

export const fetchUpcomingLiveClass = async () => {
  const { data } = await axios.get("/live/classes");
  return data;
};

export const fetchCompletedLiveClass = async () => {
  const { data } = await axios.get("/completed/live/classes");
  return data;
};

export const fetchTokens = async (query) => {
  const liveData = await axios.get("/live/token", {
    params: {
      channel: query,
      username: "",
    },
  });
  const classToken = liveData.data.data.token;

  const messageData = await axios.get("/live/messaging/token", {
    params: {
      account: query,
    },
  });
  const messageToken = messageData.data.data.token;
  return { name: query, classToken, messageToken };
};
