import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  fetchCompletedLiveClass,
  fetchTokens,
  fetchUpcomingLiveClass,
} from "../../../services/live";
import {
  setCompletedLiveClasses,
  setUpcomingLiveClasses,
} from "../../../store/build/dataSlice";
import { toDateTimeString } from "../../../utils/utils";
import AddButton from "../../shared/AddButton";
import ContentDialog from "../../shared/ContentDialog";
import classes from "./Common.module.scss";
export default function Live(props) {
  const dispatch = useDispatch();
  const [value, setValue] = useState("upcoming");
  const [liveCreds, setLiveCreds] = useState(false);
  const [liveCredsData, setLiveCredsData] = useState({});
  const upcoming = useQuery("upcoming-live", fetchUpcomingLiveClass, {
    onSuccess: (data) => {
      dispatch(setUpcomingLiveClasses(data.data));
    },
  });

  const completed = useQuery("completed-live", fetchCompletedLiveClass, {
    onSuccess: (data) => {
      dispatch(setCompletedLiveClasses(data.data));
    },
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const { mutateAsync: getTokenFunction } = useMutation(fetchTokens);

  const generateTokenHandler = async (channel) => {
    await getTokenFunction(channel, {
      onSuccess: (data) => {
        setLiveCreds(true);
        setLiveCredsData(data);
      },
    });
  };

  useEffect(() => {
    if (upcoming !== null) dispatch(setUpcomingLiveClasses(upcoming.data));

    if (completed !== null) dispatch(setCompletedLiveClasses(completed.data));
  }, [dispatch, upcoming, completed]);

  if (value === "upcoming" && (isNil(upcoming) || upcoming.isLoading))
    return null;
  if (value === "completed" && (isNil(completed) || completed.isLoading))
    return null;

  return (
    <div className={`${props.class.container}`}>
      <div className={props.class.heading}>
        <h1>Live Class</h1>
        <AddButton color="secondary">Add New Live Class</AddButton>
      </div>
      <FormControl>
        <RadioGroup
          className="flex !flex-row"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="upcoming"
            control={<Radio />}
            label="Upcoming Live Class"
          ></FormControlLabel>
          <FormControlLabel
            value="completed"
            control={<Radio />}
            label="Completed Live Class"
          ></FormControlLabel>
        </RadioGroup>
      </FormControl>
      <div className={props.class.sub_container}>
        {value === "upcoming"
          ? upcoming.data.data.map((live, idx) => (
              <LiveCard
                key={live._id}
                data={live}
                generateToken={() => generateTokenHandler(live.name)}
              />
            ))
          : completed.data.data.map((live, idx) => (
              <LiveCard key={live._id} data={live} />
            ))}
      </div>
      <ContentDialog
        isOpen={liveCreds}
        closeDialog={() => setLiveCreds(false)}
        title="Secret Tokens"
        action="Close"
      >
        <div>
          <div
            onClick={() => {
              navigator.clipboard.writeText(liveCredsData.name);
              toast.success("Copied Class Name");
            }}
            className="cursor-pointer underline text-blue-700"
          >
            Click To Copy Class Name
          </div>
          <div
            onClick={() => {
              navigator.clipboard.writeText(liveCredsData.classToken);
              toast.success("Copied Secret Token");
            }}
            className="cursor-pointer underline text-blue-700"
          >
            Click To Copy Secret Token
          </div>
          <div
            className="cursor-pointer underline text-blue-700"
            onClick={() => {
              navigator.clipboard.writeText(liveCredsData.messageToken);
              toast.success("Copied Chat Token");
            }}
          >
            Click To Copy Chat Token
          </div>
        </div>
      </ContentDialog>
    </div>
  );
}

const LiveCard = (props) => {
  return (
    <Card variant="outlined relative">
      <CardContent>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className={classes.grid_with_divider}>Name</p>
            <Divider />
            <h3>{props.data.name}</h3>
          </div>
          <div>
            <p className={classes.grid_with_divider}>Subject</p>
            <Divider />
            <h3 className="font-normal">{props.data.subject}</h3>
          </div>
          <div>
            <p className={classes.grid_with_divider}>Start Time</p>
            <Divider />
            <h3 className="font-normal text-sm">
              {toDateTimeString(props.data.start)}
            </h3>
          </div>
          <div>
            <p className="text-sm font-thin">End Time</p>
            <Divider />
            <h3 className="font-normal text-sm">
              {toDateTimeString(props.data.end)}
            </h3>
          </div>
          {props.data.interested && (
            <div className="absolute top-1 right-1 bg-white rounded p-2">
              {props.data.interested.length}
            </div>
          )}
        </div>
      </CardContent>
      <CardActions>
        {props.generateToken && (
          <Button onClick={props.generateToken}>Generate Token</Button>
        )}
      </CardActions>
    </Card>
  );
};
