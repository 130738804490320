import { Delete } from "@mui/icons-material";
import { Card, CardContent, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { fetchAllCoupons } from "../../../services/coupon";
import AddButton from "../../shared/AddButton";
import ConfirmationActionDialog from "../../shared/ConfirmationActionDialog";

export default function Coupons(props) {
  const [deleteOpen, setdeleteOpen] = useState(false);
  const coupons = useQuery("coupons", fetchAllCoupons);

  return (
    <div className={`${props.class.container}`}>
      <div className={props.class.heading}>
        <h1>Coupons</h1>
        <AddButton color="secondary">Add New Coupon</AddButton>
      </div>
      <div className={props.class.sub_container}>
        {coupons.isSuccess &&
          coupons.data.data.map((coupon) => (
            <Card
              className="font-normal text-sm"
              variant="outlined"
              key={coupon._id}
            >
              <CardContent className="h-full grid grid-cols-2 items-stretch gap-3">
                <div>{coupon.name}</div>
                <div className="justify-self-end">INR {coupon.value}</div>
                <div className="font-bold">{coupon.coupon_code}</div>
                <IconButton
                  className="justify-self-end cursor-pointer"
                  onClick={() => setdeleteOpen(true)}
                >
                  <Delete color="error" />
                </IconButton>
              </CardContent>
            </Card>
          ))}
      </div>
      <ConfirmationActionDialog
        isOpen={deleteOpen}
        closeDialog={() => setdeleteOpen(false)}
        title="Are you Sure you Want to Delete"
        action="Delete"
      />
    </div>
  );
}
