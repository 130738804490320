import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  "modal.addSubject": { open: false, refetch: false },
  "modal.addCourse": { open: false, refetch: false, id: "" },
  "modal.addCrashCourse": { open: false, refetch: false },
  "modal.addClass": { open: false, refetch: false },
  "modal.addStory": { open: false, refetch: false },
  "modal.addQuiz": { open: false, refetch: false },
  "modal.addCompetition": { open: false, refetch: false },
  "modal.addLiveClass": { open: false, refetch: false },
  "modal.addBoard": { open: false, refetch: false, id: "" },
  "modal.addEnoteCourses": { open: false, refetch: false },
  "modal.addNotesToCourses": {
    open: false,
    refetch: false,
    id: "",
    course_id: "",
  },
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalState(state, action) {
      state[action.payload.modal] = action.payload.state;
    },
  },
});

export const { setModalState } = modalSlice.actions;
export default modalSlice.reducer;
