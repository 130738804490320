import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider as ReduxProvider } from "react-redux";
import store, { persistor } from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClientProvider } from "react-query";
import Loading from "./components/shared/Loading";
import ThemeWrapper from "./wrappers/ThemeWrapper";
import { Toaster } from "react-hot-toast";
import { ReactQueryDevtools } from "react-query/devtools";
import queryClient from "./services/react-query";
import ModalWrapper from "./modals";

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
        <ThemeWrapper>
          <Loading />
          <ModalWrapper />
          <App />
          <Toaster
            position="bottom-center"
            toastOptions={{
              duration: 4000,
              className: "toast",
            }}
          />
        </ThemeWrapper>
      </QueryClientProvider>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById("root")
);
