import { Button, IconButton } from "@mui/material";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { fetchStories } from "../../../services/stories";
import { setStories } from "../../../store/build/dataSlice";
import { Delete } from "@mui/icons-material";
import ConfirmationActionDialog from "../../shared/ConfirmationActionDialog";
import ContentDialog from "../../shared/ContentDialog";
import { S3_CDN } from "../../../constants/constants";
import AddButton from "../../shared/AddButton";
export default function Stories(props) {
  const dispatch = useDispatch();
  const [deleteOpen, setdeleteOpen] = useState(false);
  const [imageOpen, setimageOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const stories = useQuery("stories", fetchStories, {
    onSuccess: (data) => {
      dispatch(setStories(data.data));
    },
  });

  const deleteStoryHandler = () => {
    setdeleteOpen(true);
  };

  const openImageHandler = (title, url) => {
    setimageOpen(true);
    setDialogTitle(title);
    setImageUrl(`${S3_CDN}/${url}`);
  };

  useEffect(() => {
    if (stories) dispatch(setStories(stories.data));
  }, [stories, dispatch]);

  if (isNil(stories.data) || stories.isLoading) return null;

  return (
    <div className={`${props.class.container}`}>
      <div className={props.class.heading}>
        <h1>Stories</h1>
        <AddButton color="secondary">Add New Story</AddButton>
      </div>
      <div className={props.class.sub_container}>
        {stories.data.data.map((story, index) => (
          <div className="bg-gray-200 p-3" key={story._id}>
            <div className="flex flex-row justify-between items-center">
              <div className="text-sm w-24 lg:16">{story.title}</div>
              <div className="flex flex-col justify-between lg:flex-row w-1/2">
                <Button
                  variant="outlined"
                  onClick={() => openImageHandler("Image", story.key_name)}
                  className="mb-1 lg:m-0"
                >
                  View Image
                </Button>
                <Button
                  variant="outlined"
                  onClick={() =>
                    openImageHandler("Image", story.key_name_thumbnail)
                  }
                >
                  View Thumbnail
                </Button>
              </div>
              <IconButton onClick={() => setdeleteOpen(true)}>
                <Delete className="text-red-600 cursor-pointer" />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
      <ConfirmationActionDialog
        isOpen={deleteOpen}
        closeDialog={() => setdeleteOpen(false)}
        title="Are you Sure you Want to Delete"
        action="Delete"
      />
      <ContentDialog
        isOpen={imageOpen}
        closeDialog={() => setimageOpen(false)}
        title={dialogTitle}
        action="Close"
      >
        <img src={imageUrl} alt="Urls"></img>
      </ContentDialog>
    </div>
  );
}
