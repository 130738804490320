import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "./storage";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import authReducer from "./auth/authSlice";
import buildReducer from "./build/buildSlice";
import dataReducer from "./build/dataSlice";
import modalReducer from "./modal/modalSlice";

const reducers = combineReducers({
  auth: authReducer,
  build: buildReducer,
  data: dataReducer,
  modal: modalReducer,
});

const persistedReducers = persistReducer(
  { key: "lyrics-admin", storage },
  reducers
);

const store = configureStore({
  reducer: persistedReducers,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    });
  },
});

export const persistor = persistStore(store);
export default store;
