import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "react-query";
import {
  fetchCompetitionEntries,
  selectTopTenPost,
} from "../../../services/competitions";
import ContentDialog from "../../shared/ContentDialog";
import ReactHlsPlayer from "react-hls-player/dist";
import { S3_CDN } from "../../../constants/constants";

export default function CompetitionEntries(props) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [value, setValue] = useState("junior");
  const [modal, setModal] = useState({ open: false, video: "" });
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const entries = useQuery(
    ["competitionEntries", searchParams.get("id")],
    fetchCompetitionEntries
  );
  useEffect(() => {}, [dispatch]);
  const { mutateAsync: selectTopTenMutation } = useMutation(selectTopTenPost);

  const onSubmit = async (category, id) => {
    await selectTopTenMutation(
      { category, id, competition_id: searchParams.get("id") },
      {
        onError: (error) => {
          toast.error(error.message);
        },
        onSubmit: (data) => {
          toast.success("Success");
        },
      }
    );
  };
  if (isNil(entries) || entries.isLoading) return null;

  return (
    <div className={`${props.class.container}`}>
      <div className={props.class.heading}>
        <h1>Competition Entries</h1>
      </div>
      <FormControl>
        <RadioGroup
          className="flex !flex-row"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="junior"
            control={<Radio />}
            label="Junior"
          ></FormControlLabel>
          <FormControlLabel
            value="senior"
            control={<Radio />}
            label="Senior"
          ></FormControlLabel>
        </RadioGroup>
      </FormControl>
      <div className={props.class.sub_container}>
        {value === "junior"
          ? entries.data.data.jr.map((entry) => (
              <Card key={entry._id}>
                <CardContent>{entry.student_id.name}</CardContent>
                <CardActions>
                  <Button
                    onClick={() => setModal({ open: true, video: entry.video })}
                  >
                    View Submission
                  </Button>
                  {!entry.isShortlisted && (
                    <Button
                      color="success"
                      onClick={() => onSubmit("jr", entry._id)}
                    >
                      Select for Top Ten
                    </Button>
                  )}
                </CardActions>
              </Card>
            ))
          : entries.data.data.sr.map((entry) => (
              <Card key={entry._id}>
                <CardContent>{entry.student_id.name}</CardContent>
                <CardActions>
                  <Button
                    onClick={() => setModal({ open: true, video: entry.video })}
                  >
                    View Submission
                  </Button>
                  {!entry.isShortlisted && (
                    <Button
                      color="success"
                      onClick={() => onSubmit("sr", entry._id)}
                    >
                      Select for Top Ten
                    </Button>
                  )}
                </CardActions>
              </Card>
            ))}
      </div>

      <ContentDialog
        isOpen={modal.open}
        closeDialog={() => setModal({ open: false, video: "" })}
        title="Entry Submission"
        action="Close"
      >
        {modal.video !== "" && (
          <ReactHlsPlayer
            controls={true}
            autoPlay={true}
            src={`${S3_CDN}/competition/${modal.video}`}
            className="w-[80vw] h-[60vh]"
            hlsConfig={{
              maxLoadingDelay: 4,
              minAutoBitrate: 1200000,
            }}
          />
        )}
      </ContentDialog>
    </div>
  );
}
