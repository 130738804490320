import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebar: {
    left: { open: false },
  },
  theme: "light",
  files: [],
  dateTime: [],
};

const buildSlice = createSlice({
  name: "build",
  initialState,
  reducers: {
    setTheme(state, action) {
      state.theme = action.payload;
    },
    addFile(state, action) {
      state.files = state.files.concat(action.payload);
    },
    deleteFile(state, action) {
      state.files = state.files.filter((x) => x.name !== action.payload);
    },
    addDateTime(state, action) {
      state.dateTime = state.dateTime.concat(action.payload);
    },
    replaceDateTime(state, action) {
      state.dateTime = state.dateTime.filter(
        (x) => x.name !== action.payload.name
      );
      state.dateTime = state.dateTime.concat(action.payload);
    },
    deleteDateTime(state, action) {
      state.dateTime = state.dateTime.filter((x) => x.name !== action.payload);
    },
  },
});

export const {
  setTheme,
  addFile,
  deleteFile,
  addDateTime,
  deleteDateTime,
  replaceDateTime,
} = buildSlice.actions;
export const getFiles = (state) => state.build.files;
export const getDateTime = (state) => state.build.dateTime;
export const getTheme = (state) => state.build.theme;

export default buildSlice.reducer;
