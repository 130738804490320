import {
  Home,
  Subject,
  Person,
  Quiz,
  MilitaryTech as Competition,
  LiveTv,
  AddPhotoAlternate as Story,
  Discount as Coupon,
  Settings,
  Logout,
} from "@mui/icons-material";

const SidebarItems = [
  {
    id: "Home",
    icon: <Home fontSize="large" />,
  },
  {
    id: "Subjects",
    icon: <Subject fontSize="large" />,
  },
  {
    id: "Teachers",
    icon: <Person fontSize="large" />,
  },
  {
    id: "Quiz",
    icon: <Quiz fontSize="large" />,
  },
  {
    id: "Competition",
    icon: <Competition fontSize="large" />,
  },
  {
    id: "Live Class",
    icon: <LiveTv fontSize="large" />,
  },
  {
    id: "Stories",
    icon: <Story fontSize="large" />,
  },
  {
    id: "Coupons",
    icon: <Coupon fontSize="large" />,
  },
  {
    id: "Settings",
    icon: <Settings fontSize="large" />,
  },
  {
    id: "Logout",
    icon: <Logout fontSize="large" color="error" />,
  },
];

export default SidebarItems;
