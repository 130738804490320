import React from "react";
import Competition from "../Sub-Sections/Competition";
import Home from "../Sub-Sections/Home";
import Live from "../Sub-Sections/Live";
import Quiz from "../Sub-Sections/Quiz";
import Teacher from "../Sub-Sections/Teacher";
import Subjects from "../Sub-Sections/Subjects";
import Coupons from "../Sub-Sections/Coupons";
import Settings from "../Sub-Sections/Settings";
import Stories from "../Sub-Sections/Stories";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import classes from "../Sub-Sections/Common.module.scss";
import Course from "../Sub-Sections/Course";
import Classes from "../Sub-Sections/Classes";
import CrashCourse from "../Sub-Sections/CrashCourse";
import CompetitionEntries from "../Sub-Sections/CompetitionEntries";
import Enotes from "../Sub-Sections/Enotes";
import EnotesCourses from "../Sub-Sections/NotesCourses";
export default function Center() {
  return (
    <div className="col-start-2 col-end-9 md:col-start-3 md:col-end-9 px-4 pt-4">
      <Routes>
        <Route path="home" element={<Home class={classes} />} />
        <Route exact path="subjects" element={<Subjects class={classes} />} />
        <Route path="subjects/course" element={<Course class={classes} />} />
        <Route
          path="subjects/crashcourse"
          element={<CrashCourse class={classes} />}
        />
        <Route
          path="subjects/course/classes"
          element={<Classes class={classes} />}
        />
        <Route
          path="subjects/crashcourse/classes"
          element={<Classes class={classes} />}
        />
        <Route path="subjects/enotes" element={<Enotes class={classes} />} />
        <Route
          path="subjects/enotes/courses"
          element={<EnotesCourses class={classes} />}
        />
        <Route path="coupons" element={<Coupons class={classes} />} />
        <Route path="quiz" element={<Quiz class={classes} />} />
        <Route path="competition" element={<Competition class={classes} />} />
        <Route
          path="competition/entries"
          element={<CompetitionEntries class={classes} />}
        />
        <Route path="settings" element={<Settings class={classes} />} />
        <Route path="teachers" element={<Teacher class={classes} />} />
        <Route path="liveclass" element={<Live class={classes} />} />
        <Route path="stories" element={<Stories class={classes} />} />
        <Route path="*" element={<div>No Route</div>} />
      </Routes>
    </div>
  );
}
