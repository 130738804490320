import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

export default function ConfirmationActionDialog(props) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.closeDialog}
      aria-labelledby="title"
    >
      <DialogTitle id="title">{props.title}</DialogTitle>
      <DialogActions>
        <Button variant="text" onClick={props.closeDialog}>
          No
        </Button>
        <Button variant="outlined" onClick={props.confirmAction}>
          {props.action}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
