import React, { useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  addDateTime,
  getDateTime,
  replaceDateTime,
} from "../../store/build/buildSlice";
import { isEmpty } from "lodash";
export default function DateTimePickerCompnent({ isTime, name, state }) {
  const dispatch = useDispatch();
  const date = useSelector(getDateTime);
  const [value, setValue] = useState();
  const dateValue = useMemo(
    () => date.filter((x) => x.name === state),
    [date, state]
  );

  const addDateTimeHandler = (newValue) => {
    setValue(newValue);
    isEmpty(dateValue)
      ? dispatch(addDateTime({ name: state, value: newValue }))
      : dispatch(replaceDateTime({ name: state, value: newValue }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isTime ? (
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          label={name}
          value={value}
          onChange={(newValue) => {
            addDateTimeHandler(newValue);
          }}
        />
      ) : (
        <DatePicker
          label={name}
          value={value}
          onChange={(newValue) => {
            addDateTimeHandler(newValue);
          }}
          //   error={!!fieldState.error}
          renderInput={(params) => <TextField {...params} />}
        />
      )}
    </LocalizationProvider>
  );
}
