import { Delete, PhotoCamera } from "@mui/icons-material";
import { IconButton, Input } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addFile, deleteFile } from "../../store/build/buildSlice";

export default function UploadImageButton({ name, filePath, isImage = true }) {
  const [image, setimage] = useState();
  const dispatch = useDispatch();
  const uploadCourseImage = (event) => {
    // if (event.target.files[0].size > 50000) {
    //   alert("File size is too large, Compress Image under 50KB");
    //   return;
    // } else {
    setimage(URL.createObjectURL(event.target.files[0]));
    dispatch(addFile({ name: filePath, image: event.target.files[0] }));
    // }
  };

  const deleteImageHandler = () => {
    dispatch(deleteFile(filePath));
    setimage(null);
  };

  return (
    <div className="m-auto flex flex-col items-center">
      {image ? (
        <img src={image} alt="Course" className="w-1/2 h-1/2" />
      ) : (
        <div className="bg-slate-200 w-[80vw] h-[16vh] lg:w-[20vw] lg:h-[16vh] m-auto text-center flex flex-col justify-center items-center">
          {name}
        </div>
      )}

      {!image ? (
        <label htmlFor="icon-button-file">
          <Input
            accept="image/*"
            id="icon-button-file"
            type="file"
            className="hidden"
            onChange={uploadCourseImage}
          />
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <PhotoCamera />
          </IconButton>
        </label>
      ) : (
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={deleteImageHandler}
        >
          <Delete color="error" />
        </IconButton>
      )}
    </div>
  );
}
