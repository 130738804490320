import axios from "./axios";

export const fetchAllCompetitions = async () => {
  const { data } = await axios.get("/competitions");
  return data;
};

export const addNewCompetition = async ({
  start,
  entry_end,
  top_ten_end,
  voting_lines_end,
  result_end,
  name,
  about,
  picture,
}) => {
  var formData = new FormData();
  formData.append("name", name);
  formData.append("start", start);
  formData.append("picture", picture);
  formData.append("result_end", result_end);
  formData.append("voting_lines_end", voting_lines_end);
  formData.append("entry_end", entry_end);
  formData.append("top_ten_end", top_ten_end);

  for (var i = 0; i < about.length; i++) {
    formData.append(`about[${i}]`, about[i]);
  }

  await axios.post("/create/competition", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const fetchCompetitionEntries = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await axios.get("/get/competition/entries", {
    params: { id },
  });

  return data;
};

export const selectTopTenPost = async ({ category, id, competition_id }) => {
  console.log({ category, id, competition_id });
  await axios.post("/select/competition/entry", {
    category,
    id,
    competition_id,
  });
};
