import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export default function ContentDialog(props) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.closeDialog}
      aria-labelledby="title"
    >
      <DialogTitle id="title">{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.closeDialog}>
          {props.action}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
