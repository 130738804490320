import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getIsLoggedIn } from "../../store/auth/authSlice";
import Center from "./Sections/Center";
import Sidebar from "./Sections/Sidebar";

export default function Dashboard() {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(getIsLoggedIn);

  useEffect(
    () => !isLoggedIn && navigate("/", { replace: true }),
    [isLoggedIn, navigate]
  );
  return (
    <div className="grid grid-cols-8">
      <Sidebar />
      <Center />
      {/* <Center /> All routes are configured here */}
    </div>
  );
}
