import { Button, Card, CardContent } from "@mui/material";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { S3_CDN } from "../../../constants/constants";
import { fetchTeachers } from "../../../services/teachers";
import { setTeachers } from "../../../store/build/dataSlice";
import AddButton from "../../shared/AddButton";
import ContentDialog from "../../shared/ContentDialog";

export default function Teacher(props) {
  const dispatch = useDispatch();
  const [imageOpen, setimageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const teachers = useQuery("teachers", fetchTeachers, {
    onSuccess: (data) => {
      dispatch(setTeachers(data.data));
    },
  });

  const clickHandler = (url) => {
    setimageOpen(true);
    setImageUrl(`${S3_CDN}/${url}`);
  };
  useEffect(() => {
    if (teachers !== null) dispatch(setTeachers(teachers.data));
  }, [dispatch, teachers]);

  if (isNil(teachers.data) || teachers.isLoading) return null;
  return (
    <div className={`${props.class.container}`}>
      <div className={props.class.heading}>
        <h1>Teachers</h1>
        <AddButton color="secondary">Add New Teacher</AddButton>
      </div>
      <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4">
        {teachers.data.data.map((teacher, idx) => (
          <Card className="h-max" variant="outlined" key={teacher._id}>
            <CardContent>
              <h1>{teacher.name}</h1>
              <ul className="list-disc text-sm flex flex-col my-3 font-normal pl-2">
                <li>{teacher.success}</li>
                <li>{teacher.degree}</li>
                <li>{teacher.experience}</li>
                <li>{teacher.trained}</li>
              </ul>
              <div className="grid grid-cols-2 gap-1 h-auto mb-2">
                {teacher.courses.map((course, index) => (
                  <div
                    className="w-auto text-center bg-white py-1 px-3 mb-1 rounded-md"
                    key={course._id}
                  >
                    <p className="text-sm font-normal my-auto lg:truncate">
                      {`${course.subject_id.name} ${course.name}`.trim()}
                    </p>
                  </div>
                ))}
              </div>
              <Button onClick={() => clickHandler(teacher.key_name)}>
                View Image
              </Button>
            </CardContent>
          </Card>
        ))}
      </div>
      <ContentDialog
        isOpen={imageOpen}
        closeDialog={() => setimageOpen(false)}
        title="Teacher Image"
        action="Close"
      >
        <img src={imageUrl} alt="Urls"></img>
      </ContentDialog>
    </div>
  );
}
