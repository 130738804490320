export const BASE_URL = "https://backend.lyricsacademy.in/admin/";
//export const BASE_URL = "http://localhost:5000/admin/";
export const VIDEO_URL =
  "https://d9kj6xjcfol40.cloudfront.net/lectures/Default/lectures/";
export const NOTES_URL = "https://d9kj6xjcfol40.cloudfront.net/notes/";
export const S3_CDN = "https://d9kj6xjcfol40.cloudfront.net";

export const modal = {
  subject: "modal.addSubect",
  course: "modal.addCourse",
  crashCourse: "modal.addCrashCourse",
  class: "modal.addClass",
  story: "modal.addStory",
  quiz: "modal.addQuiz",
  competition: "modal.addCompetition",
  live: "modal.addLiveClass",
};
