import { get, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { S3_CDN } from "../../../constants/constants";
import { fetchCoursesBySubject } from "../../../services/subjects";
import { setCourses } from "../../../store/build/dataSlice";
import { setModalState } from "../../../store/modal/modalSlice";
import AddButton from "../../shared/AddButton";
import ContentDialog from "../../shared/ContentDialog";
import CourseCard from "../../shared/CourseCard";

export default function Course(props) {
  const [viewInfo, setViewInfo] = useState(false);
  const [viewCourse, setViewCourse] = useState({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const courses = useQuery(
    ["course", searchParams.get("id")],
    fetchCoursesBySubject,
    {
      onSuccess: (data) => {
        dispatch(setCourses(data.data));
      },
    }
  );

  useEffect(() => {
    if (courses.data) dispatch(setCourses(courses.data));
  }, [dispatch, courses]);

  const { refetch: isRefetch } = useSelector((state) =>
    get(state.modal, "modal.addCourse")
  );

  const openModalHandler = () => {
    dispatch(
      setModalState({
        modal: "modal.addCourse",
        state: { open: true, refetch: false, id: searchParams.get("id") },
      })
    );
  };

  useEffect(() => {
    console.log("refetch");
    async function refetchUI() {
      await courses.refetch();
    }
    refetchUI();
    dispatch(
      setModalState({
        modal: "modal.addCourse",
        state: { open: false, refetch: false, id: "" },
      })
    );
  }, [isRefetch, dispatch]);

  const viewOtherHandler = (course) => {
    setViewInfo(true);
    setViewCourse(course);
  };
  const viewClassesHandler = (name, id) => {
    navigate(
      `classes?subject=${searchParams.get("name")}&name=${name}&id=${id}`
    );
  };
  if (isNil(courses.data) || courses.isLoading) return null;
  return (
    <div className={`${props.class.container}`}>
      <div className={props.class.heading}>
        <h1>{`${searchParams.get("name")}-Courses`}</h1>
        <AddButton color="secondary" onClick={openModalHandler}>
          Add New Course
        </AddButton>
      </div>
      <div className={props.class.sub_container}>
        {courses.data.data.map((course, idx) => (
          <CourseCard
            data={course}
            key={course._id}
            viewOther={() => viewOtherHandler(course)}
            viewClasses={() => viewClassesHandler(course.name, course._id)}
          />
        ))}
      </div>
      <ContentDialog
        isOpen={viewInfo}
        closeDialog={() => setViewInfo(false)}
        title="Course Information"
        action="Close"
      >
        {viewInfo && (
          <div>
            <div className="w-1/3 h-1/3 m-auto mb-3 bg-contain">
              <img
                src={`${S3_CDN}/images/${viewCourse.picture}`}
                alt="Course"
              />
            </div>
            <div className="text-sm font-regular">{viewCourse.overview}</div>
            <h1 className="text-lg font-regular mt-2">Requirements</h1>
            <ul className="list-disc pl-4">
              {viewCourse.requirement.map((req, idx) => (
                <li key={idx}>{req}</li>
              ))}
            </ul>
            <h1 className="text-lg font-regular mt-2">What will you Learn?</h1>
            <ul className="list-disc pl-4">
              {viewCourse.learn.map((req, idx) => (
                <li key={idx}>{req}</li>
              ))}
            </ul>
          </div>
        )}
      </ContentDialog>
    </div>
  );
}
