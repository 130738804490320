import { Button, Card, CardActions, CardContent } from "@mui/material";
import { get, isEmpty, isNil } from "lodash";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchSubjects } from "../../../services/subjects";
import { setSubjects } from "../../../store/build/dataSlice";
import { setModalState } from "../../../store/modal/modalSlice";
import AddButton from "../../shared/AddButton";

export default function Subjects(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subjects = useQuery("subjects", fetchSubjects, {
    onSuccess: (data) => {
      dispatch(setSubjects(data.data));
    },
  });

  const { refetch: isRefetch } = useSelector((state) =>
    get(state.modal, "modal.addSubject")
  );

  useEffect(() => {
    if (subjects) dispatch(setSubjects(subjects.data));
  }, [subjects, dispatch]);

  const openCourseHandler = (name, subject_id) => {
    navigate(`course?name=${name}&id=${subject_id}`);
  };
  const openCrashCourseHandler = (name, subject_id) => {
    navigate(`crashcourse?name=${name}&id=${subject_id}`);
  };

  const openEnotesHandler = (name, subject_id) => {
    navigate(`enotes?name=${name}&id=${subject_id}`);
  };

  const openModalHandler = () => {
    dispatch(
      setModalState({
        modal: "modal.addSubject",
        state: { open: true, refetch: false },
      })
    );
  };

  useEffect(() => {
    async function refetchUI() {
      await subjects.refetch();
    }
    refetchUI();
    dispatch(
      setModalState({
        modal: "modal.addSubject",
        state: { open: false, refetch: false },
      })
    );
  }, [isRefetch, dispatch]);

  if (!subjects || isEmpty(subjects) || isNil(subjects) || subjects.isLoading)
    return null;
  return (
    <div className={`${props.class.container}`}>
      <div className={props.class.heading}>
        <h1>Subjects</h1>
        <AddButton color="secondary" onClick={openModalHandler}>
          Add New Subject
        </AddButton>
      </div>

      <div className="lg:grid lg:grid-cols-2 m-auto">
        {subjects.data.data.map((subject, index) => (
          <SubjectCard
            subject={subject}
            key={subject._id}
            course={() => openCourseHandler(subject.name, subject._id)}
            crashCourse={() =>
              openCrashCourseHandler(subject.name, subject._id)
            }
            enotes={() => {
              openEnotesHandler(subject.name, subject._id);
            }}
          />
        ))}
      </div>
    </div>
  );
}

const SubjectCard = (props) => {
  return (
    <Card
      className="relative w-full h-40 flex flex-col w-9/10 mb-6 lg:mb-6 lg:w-4/6 m-auto justify-between"
      variant="outlined"
    >
      <CardContent>{props.subject.name}</CardContent>
      <CardActions className="flex flex-row justify-between mt-1 bottom-0">
        <Button onClick={props.course}> Courses</Button>
        <Button onClick={props.enotes}> E-Notes</Button>
        <Button onClick={props.crashCourse}>Crash Courses</Button>
      </CardActions>
    </Card>
    // </div>
  );
};
