import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getPermissions } from "../../store/auth/authSlice";

export default function AddButton(props) {
  const permissions = useSelector(getPermissions);
  return permissions.add ? (
    <Button color="secondary" onClick={props.onClick}>
      {props.children}
    </Button>
  ) : (
    <Button color="secondary" disabled>
      {props.children}
    </Button>
  );
}
