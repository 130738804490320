import React from "react";
import AddBoard from "./sections/AddBoard";
import AddCompetition from "./sections/AddCompetition";
import AddCourse from "./sections/AddCourse";
import AddEnoteCourses from "./sections/AddEnoteCourses";
import AddNewNotesToCourses from "./sections/AddNewNotesToCourse";
import AddSubject from "./sections/AddSubject";

export default function ModalWrapper() {
  return (
    <>
      <AddSubject />
      <AddCourse />
      <AddCompetition />
      <AddBoard />
      <AddEnoteCourses />
      <AddNewNotesToCourses />
    </>
  );
}
