import toast from "react-hot-toast";
import store from "../store";
import {
  setAccessToken,
  setLoggedIn,
  setPermission,
} from "../store/auth/authSlice";
import axios from "./axios";

export const login = async (params) => {
  const {
    data: { sessionToken, permission },
  } = await axios.post("/login", params);

  store.dispatch(setAccessToken(sessionToken));
  store.dispatch(setLoggedIn(true));

  store.dispatch(setPermission(permission));
};
