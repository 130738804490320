import { Button, Card, CardActions, CardContent, Divider } from "@mui/material";
import React from "react";
import classes from "../build/Sub-Sections/Common.module.scss";
export default function CourseCard(props) {
  return (
    <Card variant="outlined">
      <CardContent>
        <p className="mb-2">{props.data.name}</p>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm">Original Price</p>
            <Divider />
            <p className={classes.grid_with_divider}>{props.data.price}</p>
          </div>
          <div>
            <p className="text-sm">Android Price</p>
            <Divider />
            <p className={classes.grid_with_divider}>
              {props.data.discount_price}
            </p>
          </div>
          <div>
            <p className="text-sm">iOS Price</p>
            <Divider />
            <p className={classes.grid_with_divider}>{props.data.i_price}</p>
          </div>
        </div>
        <div className="my-2 text-base">Teachers</div>
        <div className="grid grid-cols-3 gap-2">
          {props.data.teachers.map((teacher) => (
            <div className="text-sm p-2 bg-white text-center" key={teacher._id}>
              {teacher.name}
            </div>
          ))}
        </div>
      </CardContent>
      <CardActions>
        <Button onClick={props.viewOther}>View Other Details</Button>
        <Button onClick={props.viewClasses} color="secondary">
          View All Classes
        </Button>
      </CardActions>
    </Card>
  );
}
