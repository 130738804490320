import { Button, Card, CardActions, CardContent, Divider } from "@mui/material";
import { get, isEmpty, isNil } from "lodash";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCompetitions } from "../../../services/competitions";
import { setModalState } from "../../../store/modal/modalSlice";
import AddButton from "../../shared/AddButton";
import { toDateTimeString } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
export default function Competition(props) {
  const dispatch = useDispatch();
  const competitions = useQuery("competitions", fetchAllCompetitions, {});
  const navigate = useNavigate();
  const { refetch: isRefetch } = useSelector((state) =>
    get(state.modal, "modal.addCompetition")
  );

  useEffect(() => {
    async function refetchUI() {
      await competitions.refetch();
    }
    refetchUI();
    dispatch(
      setModalState({
        modal: "modal.addCompetition",
        state: { open: false, refetch: false },
      })
    );
  }, [isRefetch, dispatch]);

  const openModalHandler = () => {
    dispatch(
      setModalState({
        modal: "modal.addCompetition",
        state: { open: true, refetch: false },
      })
    );
  };
  const viewEntriesHandler = (id) => {
    navigate(`entries?id=${id}`);
  };
  if (
    !competitions ||
    isEmpty(competitions) ||
    isNil(competitions) ||
    competitions.isLoading
  )
    return null;
  return (
    <div className={`${props.class.container}`}>
      <div className={props.class.heading}>
        <h1>Competition</h1>
        <AddButton color="secondary" onClick={openModalHandler}>
          Add New Competition
        </AddButton>
      </div>
      <div className={props.class.sub_container}>
        {competitions.data.data.map((competition, idx) => (
          <Card variant="outlined">
            <CardContent>
              <div>{competition.name}</div>
              <DateComponent title="Start Date" date={competition.start_date} />
              <DateComponent
                title="Entry End Date"
                date={competition.entry_end_date}
              />
              <DateComponent
                title="Top Ten End Date"
                date={competition.top_ten_end_date}
              />
              <DateComponent
                title="Voting Lines End Date"
                date={competition.voting_lines_end_date}
              />
              <DateComponent
                title="Result End Date"
                date={competition.result_end_date}
              />
            </CardContent>
            <CardActions>
              <Button onClick={() => viewEntriesHandler(competition._id)}>
                View Entries
              </Button>
            </CardActions>
          </Card>
        ))}
      </div>
    </div>
  );
}

const DateComponent = ({ title, date }) => {
  return (
    <div className="grid grid-cols-2 gap-4 mt-4">
      <div className="text-sm font-regular">{title}</div>
      <div className="text-sm font-regular">{toDateTimeString(date)}</div>
      <Divider />
    </div>
  );
};
