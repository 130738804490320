import { Button, Card, CardActions, CardContent, Divider } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import ReactHlsPlayer from "react-hls-player/dist";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { VIDEO_URL, S3_CDN } from "../../../constants/constants";
import { getCourses } from "../../../store/build/dataSlice";
import AddButton from "../../shared/AddButton";
import ContentDialog from "../../shared/ContentDialog";

export default function Classes(props) {
  const [searchParams] = useSearchParams();
  const courses = useSelector(getCourses);
  const course_id = searchParams.get("id");
  const classes = courses.data.filter((x) => x._id === course_id);
  const [popupOpen, setPopupOpen] = useState(false);
  const [watchVideo, setWatchVideo] = useState("");
  const [viewNotes, setViewNotes] = useState("");
  const [viewAssignment, setViewAssignment] = useState({});

  const popupOpenHandler = ({
    video_url = "",
    notes_url = "",
    assignment = {},
  } = {}) => {
    setWatchVideo(video_url);
    setViewNotes(notes_url);
    setViewAssignment(assignment);
    setPopupOpen(true);
  };
  const popupCloseHandler = () => {
    setWatchVideo("");
    setViewNotes("");
    setViewAssignment({});
    setPopupOpen(false);
  };

  return (
    <div className={`${props.class.container}`}>
      <div className={props.class.heading}>
        <h1>{`${searchParams.get("subject")} ${searchParams.get("name")}`}</h1>
        <AddButton color="secondary">Add New Class</AddButton>
      </div>
      <div className={props.class.sub_container}>
        {classes[0].classes.map((item, idx) => (
          <Card variant="outlined" key={item._id}>
            <CardContent className="relative">
              <h1 className="text-sm font-normal">{item.name}</h1>
              <div className="absolute right-1 top-1 text-sm font-normal bg-white w-5 h-5 rounded-full text-center">
                {idx + 1}
              </div>
            </CardContent>
            <CardActions className="grid grid-cols-2 gap-2">
              <Button
                onClick={() =>
                  popupOpenHandler({
                    video_url: `${VIDEO_URL}${item.video_url}`,
                  })
                }
              >
                Watch Video
              </Button>

              {!(item.notes_url.trim() === "") && (
                <Button
                  className="ml-0"
                  onClick={() =>
                    popupOpenHandler({
                      notes_url: `${S3_CDN}/${item.notes_url}`,
                    })
                  }
                >
                  View Notes
                </Button>
              )}
              <Button className="ml-0" variant="outlined">
                Add Assignment
              </Button>
              {!isEmpty(item.assignments) && (
                <Button
                  className="ml-0"
                  color="secondary"
                  onClick={() =>
                    popupOpenHandler({ assignment: item.assignments })
                  }
                >
                  Assignments
                </Button>
              )}
            </CardActions>
          </Card>
        ))}
      </div>
      {popupOpen && (
        <ContentDialog
          isOpen={popupOpen}
          closeDialog={popupCloseHandler}
          title="Class Information"
          action="Close"
        >
          {watchVideo !== "" && (
            <ReactHlsPlayer
              controls={true}
              autoPlay={true}
              src={watchVideo}
              className="w-[80vw] h-[60vh]"
              hlsConfig={{
                maxLoadingDelay: 4,
                minAutoBitrate: 1200000,
              }}
            />
          )}
          {viewNotes !== "" && (
            <iframe
              src={viewNotes}
              title="Notes"
              className="h-[50vh] w-[70vw] lg:h-[50vh] lg:w-[50vw]"
            />
          )}
          {!isEmpty(viewAssignment) && (
            <div>
              {viewAssignment.map((assignment, idx) => (
                <div className="mb-2">
                  <p className="font-semibold">{assignment.question}</p>
                  <ul className="list-disc p-2">
                    <li>{assignment.option1}</li>
                    <li>{assignment.option2}</li>
                    <li>{assignment.option3}</li>
                    <li>{assignment.option4}</li>
                  </ul>
                  <Divider />
                </div>
              ))}
            </div>
          )}
        </ContentDialog>
      )}
    </div>
  );
}
