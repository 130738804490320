import axios from "./axios";

export const fetchSubjects = async () => {
  const { data } = await axios.get("/subjects");
  return data;
};

export const fetchCoursesBySubject = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, subject_id] = queryKey;
  const { data } = await axios.get("/get/all/courses", {
    params: {
      subject_id,
    },
  });
  return data;
};

export const fetchCrashCoursesBySubject = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, subject_id] = queryKey;
  const { data } = await axios.get("/get/all/crash/courses", {
    params: { subject_id },
  });

  return data;
};

export const addNewSubject = async ({ name }) => {
  await axios.post("/create/subject", { name });
};

export const addNewCourse = async ({
  subject_id,
  name,
  picture,
  teacher_id,
  price,
  learn,
  overview,
  target,
  discount_price,
}) => {
  console.log(name);
  var formData = new FormData();
  formData.append("name", name);
  formData.append("subject_id", subject_id);
  formData.append("picture", picture);
  formData.append("price", price);
  formData.append("discount_price", discount_price);
  formData.append("overview", overview);
  for (var i = 0; i < teacher_id.length; i++) {
    formData.append(`teacher_id[${i}]`, teacher_id[i]._id);
  }
  for (i = 0; i < learn.length; i++) {
    formData.append(`learn[${i}]`, learn[i]);
  }
  for (i = 0; i < target.length; i++) {
    formData.append(`target[${i}]`, target[i]);
  }
  await axios.post("/create/course", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const addNewBoard = async ({ subject_id, name }) => {
  console.log({ subject_id, name });
  await axios.post("/create/new/board", { subject_id, name });
};

export const fetchBoardsBySubject = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, subject_id] = queryKey;

  const { data } = await axios.get("/boards", {
    params: {
      subject_id,
    },
  });

  return data;
};

export const addNewEnotesCourse = async ({ board_id, name }) => {
  console.log({ board_id, name });
  await axios.post("/create/new/course/board", { board_id, name });
};

export const fetchCoursesByBoard = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, board_id] = queryKey;

  const { data } = await axios.get("/board/courses", {
    params: {
      board_id,
    },
  });

  return data;
};

export const addNewNotesToCourse = async ({
  board_id,
  name,
  course_id,
  notes,
}) => {
  var formData = new FormData();
  formData.append("name", name);
  formData.append("course_id", course_id);
  formData.append("board_id", board_id);
  formData.append("file", notes);
  console.log(name, course_id, notes, board_id);
  console.log(formData);
  await axios.post("/add/notes/to/course", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
